.logo-banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    margin-top: -10%;
    margin-bottom: 5%;
}

.logo-img {
    border-radius: 10%;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}