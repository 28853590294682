.logo {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 10px;
}

.auth-btn {
    margin: 0.5rem;
}

.lab-btn {
    margin: .5rem;
}

.menu-areas {
    height: 2rem;
}


@media screen and (max-width: 1000px) {
    .logo {

        object-fit: fill;
    }
}


@media screen and (max-width: 476px) {
    .logo {

        object-fit: fill;
    }
}